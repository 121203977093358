@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "IBMPlexSansThai-Regular";
  src: url("./fonts/IBMPlexSansThai-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "IBMPlexSansThai-Light";
  src: url("./fonts/IBMPlexSansThai-Light.ttf") format("truetype");
}
@font-face {
  font-family: "IBMPlexSansThai-Bold";
  src: url("./fonts/IBMPlexSansThai-Bold.ttf") format("truetype");
}

body {
  font-family: "IBMPlexSansThai-Regular" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
