@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "IBMPlexSansThai-Regular";
  src: url("./fonts/IBMPlexSansThai-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "IBMPlexSansThai-Light";
  src: url("./fonts/IBMPlexSansThai-Light.ttf") format("truetype");
}
@font-face {
  font-family: "IBMPlexSansThai-Bold";
  src: url("./fonts/IBMPlexSansThai-Bold.ttf") format("truetype");
}

body {
  font-family: "IBMPlexSansThai-Regular" !important;
}

.cover-title {
  font-family: IBMPlexSansThai-Bold;
  text-decoration: none;
  word-wrap: break-word;
}

.shareit {
  position: fixed;
  bottom: 110px;
  right: 21px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
}

.shareit a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 55px;
  height: 55px;
  color: #fff;
  opacity: 0.75;
  transition: opacity 0.15s linear, transform 0.3s;
  text-decoration: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.shareit a:hover {
  opacity: 1;
  transform: scale(1.13);
}

.shareit a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(238, 107, 19, 0.4); */
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.3s;
  z-index: -1;
  opacity: 0;
}

.shareit a:hover::before {
  transform: scale(1.5);
  opacity: 1;
}

a:link {
  /* color: green; */
  /* background-color: transparent; */
  text-decoration: none;
}

a:hover {
  color: #ffffff !important;
  /* background-color: transparent; */
  text-decoration: underline;
}

a:active {
  /* color: yellow; */
  /* background-color: transparent; */
  text-decoration: underline;
}

@media (max-width: 768px) {
  /* (mobile) */
  .shareit {
    position: fixed;
    bottom: 110px;
    right: 21px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 2;
  }

  .shareit a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    color: #fff;
    opacity: 0.75;
    transition: opacity 0.15s linear, transform 0.3s;
    text-decoration: none;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
}

.facebook {
  background: #1877f2;
}

.phone {
  background: #e55951;
}

.line {
  background: #06c755;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.img {
  border-radius: 25px;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 58px;
  font-family: IBMPlexSansThai-Bold;
  color: #e55951;
  background-clip: text;
  background-size: cover;
  opacity: 1;
}

.subtitle {
  margin: auto;
  font-family: IBMPlexSansThai-Regular;
  color: #232323;
  font-size: 24px;
  padding-top: 5px;
  opacity: 1;
}

.titlecontent {
  font-size: 38px !important;
  font-family: IBMPlexSansThai-Bold;
  color: #303C46;
  opacity: 1;
  margin: 0 !important;
}

@media (max-width: 768px) {
  /* (mobile) */
  .titlecontent {
    font-size: 28px !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
  }
}

.titlemodal {
  font-size: 32px;
  font-family: IBMPlexSansThai-Bold;
  opacity: 1;
  margin: 0 auto;
}

.titlemodalcontact {
  font-size: 32px;
  font-family: IBMPlexSansThai-Bold;
  opacity: 1;
}

@media (max-width: 390px) {
  .titlemodal {
    text-align: center;
  }
  .subcontenttitle {
    font-size: 14px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.subcontenttitle {
  font-family: IBMPlexSansThai-Regular;
  color: #303C46;
  font-size: 18px;
  opacity: 1;
}

.subcontenttitle2 {
  font-family: IBMPlexSansThai-Light;
  color: #b3b3b3;
  font-size: 16px;
  padding-top: 8px;
  opacity: 1;
}

.container-fluid {
  /* padding: 0 !important; */
  scroll-behavior: smooth;
}

.aboutimg {
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
}

/* (desktop) */
@media (min-width: 992px) {
  .aboutimg {
    max-width: 50%;
  }
}

/* (tablet) */
@media (max-width: 991px) and (min-width: 768px) {
  .aboutimg {
    max-width: 60%;
  }
}

/* (mobile) */
@media (max-width: 767px) {
  .aboutimg {
    max-width: 90%;
  }
}

.hilight {
  margin: auto;
  font-family: IBMPlexSansThai-Bold;
  color: #e55951;
  font-size: 24px;
  padding-top: 8px;
  opacity: 1;
}

/* custim link  */

.custom-link-style {
  position: relative;
  font-family: IBMPlexSansThai-Regular;
  font-size: 16px;
  margin-right: 8px;
  cursor: pointer;
}

.custom-link-style:hover {
  color: #e55951 !important;
}

.custom-link-style:after {
  content: "";
  position: absolute;
  color: #e55951 !important;
  background-color: #e55951;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -5px;
  transition: 0.3s;
}

.custom-link-style:hover {
  color: #e55951;
}

.custom-link-style:hover:after {
  width: 100%;
}

p {
  font-size: 16px;
}

/* bottom content  */

.bottom_content {
  float: center;
  text-align: left;
  padding: 15px;
  border-radius: 20px;
  width: 500px;
  background: #ffffff;
  color: #e55951;
  height: 130px;
  justify-content: space-between;
  flex-direction: column;
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.btn {
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 12px;
}

.btn-outline-danger {
  font-family: IBMPlexSansThai-Regular;
  border: 1px solid rgb(229, 89, 81);
  border-radius: 40px;
  background: transparent;
  transition: all 0.3s ease 0s;
}

.btn-outline-danger:hover {
  background: rgb(229, 89, 81) !important;
  border: 1px solid rgb(229, 89, 81) !important;
}

/* blog content */

.blog-post {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.blog-post .blog-img .overlay,
.blog-post .blog-img .post-meta {
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  object-fit: cover;
}

.blog-post .blog-img .overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

.blog-post .blog-img .post-meta {
  bottom: 5%;
  right: 5%;
  z-index: 1;
  object-fit: cover;
}

.blog-post .content h1,
.blog-post .content h2,
.blog-post .content h3 {
  line-height: 1.2;
  font-family: IBMPlexSansThai-bold;
  color: #e55951;
}

.blog-post:hover {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.blog-post:hover .blog-img .overlay {
  opacity: 0.65;
  object-fit: cover;
}

.blog-post:hover .blog-img .post-meta {
  opacity: 1;
  object-fit: cover;
}

.blog-post .post-meta .like i,
.profile-post .like i {
  -webkit-text-stroke: 2px #dd2427;
  -webkit-text-fill-color: transparent;
}

.blog-post .post-meta .like:active i,
.blog-post .post-meta .like:focus i,
.profile-post .like:active i,
.profile-post .like:focus i {
  -webkit-text-stroke: 0px #dd2427;
  -webkit-text-fill-color: #dd2427;
}

/* Footer */

.anim {
  animation: slideUp 1s linear forwards;
  animation-delay: 1s;
}
@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
p.anim {
  animation-delay: 2s;
}
.links.anim {
  animation-delay: 3s;
  text-decoration: none;
}

footer {
  background: linear-gradient(to right, #00093c, #2d0b00);
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
}

@media screen and (min-width: 390px) {
  /* Mobile Devices */
  footer {
    padding: 50px 0 5px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .btn {
    padding: 0.5rem 1rem 0.5rem 1rem !important;
  }
}

@media screen and (min-width: 768px) {
  /* iPad Devices */
  footer {
    padding: 50px 0 5px;
  }
  .footer_logo {
    width: 100%;
    height: 110px;
  }
}

@media screen and (min-width: 992px) {
  /* Desktop Devices */
  footer {
    padding: 50px 0 5px;
    width: 100%;
  }
  .footer_logo {
    width: 50%;
    height: auto;
  }
}

.footer_logo {
  width: 50%;
}

.footer_about {
  position: relative;
  top: -13px;
  font-family: IBMPlexSansThai-Regular;
  font-size: 8px;
  color: white;
}

hr {
  width: 100%;
  border-bottom: 1px solid #ffffff !important;
}
footer .copyright {
  text-align: center;
  font-size: 14px;
  font-family: IBMPlexSansThai-Light;
  color: white;
}

.bottom_line {
  width: 100%;
  height: 5px;
  background: #e55951;
  border-radius: 3px;
}

.footersubtext {
  font-size: 14px;
  font-family: IBMPlexSansThai-Regular;
  color: white;
  text-decoration: none;
  word-wrap: break-word;
}

.footertitle {
  font-size: 24px;
  font-family: IBMPlexSansThai-Regular;
  color: white;
  text-decoration: none;
  word-wrap: break-word;
}

.bottom_line span {
  width: 15px;
  height: 100%;
  background: #f66335;
  border-radius: 3px;
}

@keyframes moveline {
  0% {
    left: -20px;
  }
  100% {
    left: 100%;
  }
}

/* Services Section */

.services .service-item {
  position: relative;
  padding-top: 40px;
}

.services .service-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
}

.services .service-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 2px;
}

.services .service-item .icon {
  width: 48px;
  height: 48px;
  position: relative;
  margin-right: 50px;
  line-height: 0;
}

.services .service-item .icon i {
  font-size: 56px;
  transition: ease-in-out 0.3s;
  z-index: 2;
  position: relative;
}

.services .service-item .icon:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  border-radius: 50px;
  z-index: 1;
  bottom: -15px;
  right: -15px;
  transition: 0.3s;
}

.services .service-item .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .service-item .description {
  line-height: 24px;
  font-size: 14px;
}

/* Card section */

.column {
  width: 100%;
  text-align: center;
}

.card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, #e55951 50%);
  background-size: 100% 200%;
  background-position: 0 1.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 0.5s;
  border-radius: 25px;
}

.card-process {
  width: fit-content;
  height: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 0.5s;
  border-radius: 25px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
  font-family: IBMPlexSansThai-Bold;
  padding-top: 5%;
}

p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
  font-family: IBMPlexSansThai-Regular;
}

.icon-wrapper {
  background-color: #e55951;
  position: relative;
  font-size: 30px;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.3s;
}

.card:hover {
  background-position: 0 100%;
}

.text-muted {
  font-size: 14px;
  font-family: IBMPlexSansThai-Bold;
}

.card:hover .icon-wrapper {
  background-color: #ffffff;
  color: #e55951;
}
.card:hover h3 {
  color: #ffffff;
  font-family: IBMPlexSansThai-Bold;
}
.card:hover p {
  color: #f0f0f0 !important;
  font-family: IBMPlexSansThai-Regular;
}

/* image hover  */

.card-product {
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.content {
  position: relative;
}

.img {
  width: 100%;
  height: auto;
  display: block;
}

.card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80%;
  padding: 18px !important;
  background: linear-gradient(to top, 
    rgba(253, 101, 55, 1) 0%, 
    rgba(253, 101, 55, 0.8) 20%, 
    rgba(253, 101, 55, 0.6) 40%, 
    rgba(253, 101, 55, 0.4) 60%, 
    rgba(253, 101, 55, 0.2) 80%, 
    rgba(253, 101, 55, 0) 100%
  );
  color: white;
}

.card-footer h4 {
  margin: 0 0 10px 0;
}

.tags {
  display: flex;
  gap: 5px;
}

.tag {
  background: transparent;
  padding: 5px 10px;
  border: 1px white solid;
  border-radius: 15px;
  font-size: 0.8em;
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
  color: white;
  padding: 15px;
  opacity: 0;
  transition: opacity 0.6s;
  overflow-y: auto;
}

.content-details {
  position: absolute;
  text-align: center;
  width: 90%;
  opacity: 0;
  z-index: 20;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-family: IBMPlexSansThai-Bold;
}

.content-details p {
  color: #fff;
  font-size: 14px;
}

.fadeIn-top {
  top: 80%;
  opacity: 0;
}

.fade-in-animation {
  animation: fadeIn 1s ease-in-out forwards;
}

/* Carousel img slide */
.carousel-container {
  max-width: 100%;
  object-fit: cover;
}

.carousel-image {
  width: 100%;
  /* height: 80vh; */
}

.carousel-inner {
  overflow: hidden;
}

.carousel-inner img {
  height: auto;
  margin: auto;
  object-fit: cover;
}

/* ปรับแต่งสีของปุ่มกดใน Carousel */
.custom-carousel-button {
  background-color: #e55951;
  color: #e55951;
}

/* ปรับแต่งสีเมื่อนำเมาส์ไปวาง */
.custom-carousel-button:hover {
  background-color: #e55951;
}

.carousel-control-next-icon {
  font-size: 24px;
  margin-right: 10px;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  /* Mobile Devices */
  section {
    padding: 2em;
  }
  .column {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .carousel-inner {
    overflow: hidden;
  }

  .carousel-image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  .carousel-inner img {
    display: block;
    height: auto;
    margin: auto;
    object-fit: cover;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  /* iPad and Tablet Devices */
  section {
    padding: 3em;
  }
  .column {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .carousel-inner {
    overflow: hidden;
  }
  .carousel-image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  .carousel-inner img {
    display: block;
    height: auto;
    margin: auto;
    object-fit: cover;
  }
}

@media screen and (min-width: 992px) {
  /* Desktop Devices */
  section {
    padding: 10em 3em;
  }
  .column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .carousel-inner {
    overflow: hidden;
  }
  .carousel-image {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  .carousel-inner img {
    display: block;
    height: auto;
    margin: auto;
    object-fit: cover;
  }
}

/* shine  effect*/

.main {
  color: #8c8c8c;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  gap: 10px;
}

img {
  -webkit-mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0) 50%,
    #000 75%
  );
  mask-image: linear-gradient(45deg, #000 25%, rgba(0, 0, 0, 0) 50%, #000 75%);
  -webkit-mask-size: 800%;
  mask-size: 800%;
  -webkit-mask-position: 0;
  mask-position: 0;
  object-fit: cover;
}

/* img:hover {
  transition: mask-position 2s ease, -webkit-mask-position 2s ease;
  -webkit-mask-position: 100%;
  mask-position: 100%;
  opacity: 1;

  color: #000;
  object-fit: cover;
} */

/* scroll to top */

#progress {
  position: fixed;
  bottom: 20px;
  right: 10px;
  height: 70px;
  width: 70px;
  display: none;
  background-color: #000;
  place-items: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(103, 103, 103, 0.2);
  cursor: pointer;
}

#progress-value {
  display: block;
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  background-color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 35px;
  color: #444343;
}

/* Navbar Styles */

.navmain {
  background-color: #ffffff;
  position: sticky !important;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
  position: sticky !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-text {
  font-size: 14px;
  font-family: IBMPlexSansThai-Bold;
  color: #1b1a1a;
}

.nav-item:last-child {
  margin-right: 0;
}

/* Active Nav Link Styles */

.active {
  color: #e55951;
  transition: 0.3s;
  text-underline-offset: 8px;
}

.portal:hover {
  color: #e55951 !important;
  transition: 0.3s;
  text-underline-offset: 8px;
}

.navbar-logo {
  max-width: 100%;
}

.logo-img {
  width: 100px;
  max-width: 100%;
}

.nav-link {
  color: rgb(36, 36, 36);
  font-family: IBMPlexSansThai-Regular !important;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.nav-text:hover {
  color: #e55951 !important;
}

.nav-link:hover {
  color: #e55951 !important;
  transition: color 0.3s ease-in-out;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  position: relative;
}

.hamburger::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  pointer-events: none;
  z-index: -1;
}

.hamburger-bar {
  width: 30px;
  height: 3px;
  background: linear-gradient(-45deg, #e55951, #ffc784);
  margin: 3px 0;
  transition: 0.4s;
  border-radius: 50%;
  width: 35px;
  height: 5px;
}

.hamburger.active .hamburger-bar:nth-child(2) {
  opacity: 0;
}

.hamburger.active .hamburger-bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.active .hamburger-bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

@media (max-width: 390px) {
  /* Mobile Device */

  .hamburger-bar {
    background: linear-gradient(-45deg, #e55951, #ffc784);
    margin: 3px 0;
    padding: 0 !important;
    transition: 0.4s;
    border-radius: 50%;
    width: 35px;
    height: 3px;
  }

  .tech-stack-image {
    width: 100% !important;
  }

  .hamburger {
    display: flex;
  }

  .navbar-logo {
    max-width: 15% !important;
  }

  .nav-link {
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
  }

  .nav-list {
    display: none;
    justify-content: start !important;
    height: 100vh !important;
  }

  .nav-item {
    margin: 0px;
    padding-bottom: 10px;
  }

  .nav-text {
    font-size: 14px;
  }

  .nav-list.active {
    display: flex;
    font-family: IBMPlexSansThai-Bold;
    flex-direction: column;
    color: #2b2b2b !important;
    align-items: center;
    background-color: white;
    width: 100%;
    position: absolute;
    top: 58px;
    right: 0;
  }
}

@media (min-width: 391px) and (max-width: 1290px) {
  /* iPad Device */
  .hamburger {
    display: flex;
  }

  .navbar-logo {
    width: 150px;
  }

  .tech-stack-image {
    width: 80% !important;
  }

  .nav-link {
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
  }

  .nav-list {
    display: none;
    justify-content: start !important;
    height: 100vh !important;
  }

  .nav-item {
    margin: 0 0px;
    padding-bottom: 10px;
  }

  .nav-text {
    font-size: 14px;
  }

  .nav-list.active {
    display: flex;
    font-family: IBMPlexSansThai-Bold;
    flex-direction: column;
    color: #2b2b2b !important;
    align-items: center;
    background-color: white !important;
    width: 100%;
    position: absolute;
    top: 58px;
    right: 0;
  }
}

@media (min-width: 1291px) {
  /* Desktop Device */
  .hamburger {
    display: none;
  }

  .tech-stack-image {
    width: 50% !important;
  }

  .navbar-logo {
    width: 80px;
  }

  .nav-list {
    display: flex;
    font-size: 16px;
    font-family: IBMPlexSansThai-Bold;
    padding-top: 10px;
  }

  .nav-item {
    margin: 0 15px;
  }

  .nav-text {
    font-size: 8px;
    color: #1b1a1a;
  }

  .nav-list.active {
    display: flex;
    background-color: transparent;
    position: static;
    top: auto;
    right: auto;
  }
}

/* Animate text */

@keyframes slideAndZoomAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%) scale(0.5);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.titlecontent.animate__animated.animate__slideAndZoom {
  animation: slideAndZoomAnimation 1.5s;
}

.fade-in-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.fade-in-on-scroll.active {
  opacity: 1;
  transform: translateY(0);
}

.np {
  padding: 0px;
}

.featuredPropBox ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: outside none none;
  padding: 0;
}

.featuredPropBox ul li {
  background-color: #eeeeee;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
  height: 300px;
  margin: 5px;
  width: 32%;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
}

.featuredPropBox ul li:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(22, 22, 22, 0.6);
  transition: all 0.3s;
}
/* 
.featuredPropBox ul li.item1 {
  background-image: url("/cover/perfectcom-img1.jpg");
  object-fit: cover;
  background-size: cover;
}

.featuredPropBox ul li.item2 {
  background-image: url("/cover/perfectcom-img2.jpg");
  object-fit: cover;
  background-size: cover;
}

.featuredPropBox ul li.item3 {
  background-image: url("/cover/perfectcom-img4.jpg");
  object-fit: cover;
  background-size: cover;
} */

.featuredPropBox ul li .fplogo {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  z-index: 1;
  transition: all 0.3s;
}

.featuredPropBox ul li .fplogo img {
  width: 100%;
}

.featuredPropBox ul li .fptext {
  display: none;
  font-size: 16px;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease 0s;
  width: 65%;
  z-index: 1;
}

.featuredPropBox ul li .fptext p {
  color: #fff;
  margin: 0px;
}

.featuredPropBox ul li:hover .title-imgcard {
  display: none;
}

.featuredPropBox ul li:hover {
  box-shadow: 0 0 0 25px rgba(0, 0, 0, 0.2) inset;
}

.featuredPropBox ul li:hover:after {
  background-color: rgba(22, 22, 22, 0.3);
}

.featuredPropBox ul li:hover .fplogo {
  display: none;
}

.featuredPropBox ul li:hover .fptext {
  display: block;
}

@media (max-width: 430px) {
  /* Mobile Device */

  .featuredPropBox ul li {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
}

.title-imgcard {
  font-size: 40px;
  font-family: IBMPlexSansThai-Bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease 0s;
  width: 65%;
  z-index: 1;
}

.title-imgcard:hover {
  display: none;
}

.icon-wrapper {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.group:hover .rotate-icon {
  transform: rotate(360deg);
}